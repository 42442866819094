<template>
  <data-table
    :headers="headers"
    :items="items"
    :last-page="serverLastPage"
    :loading="bLocalLoading"
    :options="pagination"
    :total="serverItemsLength"
    @update:sort-by="onSortBy"
    @update:sort-desc="onSortDesc"
    @update:options="onPagination"
    @update:page="onPage"
  >
    <template #[`item.name`]="{ item }">
      <name-with-avatar
        :name="item.name"
        :path="item.avatar"
        :to="{ name: 'users.update', params: { id: item.id } }"
      />
    </template>

    <template #[`item.companies`]="{ item }">
      <company-preview
        :companies="item.companies"
        hide-title
        v-if="item.companies.length"
      />
    </template>
  </data-table>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { UserData } from "@planetadeleste/vue-mc-shopaholic";
import { DataTableHeader } from "@/mixins/DataTableMixin";
import UsersMixin from "@/modules/users/mixins/UsersMixin";
import { get, map, set } from "lodash";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";
import dayjs from "dayjs";

@Component({
  components: { CompanyPreview, NameWithAvatar },
})
export default class LoggedIn extends Mixins(UsersMixin) {
  sRouteName = "users.loggedin";
  hideActionsHeader = true;

  get items(): Partial<UserData>[] {
    return map(this.obCollection.getModelList(), (obItem) => {
      const sLastLogin = get(obItem, "last_login");
      const sLastActivity = get(obItem, "last_activity_at");

      if (sLastLogin) {
        set(obItem, "last_login", dayjs(sLastLogin).format("L LT"));
      }

      if (sLastActivity) {
        set(obItem, "last_activity_at", dayjs(sLastActivity).format("L LT"));
      }

      return obItem;
    });
  }

  get isListing() {
    return true;
  }

  onBeforeIndex(obFilters: Record<string, any>) {
    set(obFilters, "logged", 1);
  }

  onRegisterEvents() {
    const arHeaders: DataTableHeader[] = [
      { text: "name", value: "name" },
      { text: "email", value: "email" },
      { text: "logged.in", value: "last_login" },
      { text: "last.activity", value: "last_activity_at" },
      { text: "companies", value: "companies" },
    ];

    this.setDTHeaders(arHeaders).mapDTHeaders();
    this.index();
  }
}
</script>
